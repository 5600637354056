export function scrollTo(selector) {
  let el = document.querySelector(selector);
  
  el.scrollIntoView({behavior: 'smooth'});
}

export function showHideScrollUpButton(topOffset) {
  let el = document.getElementById('toTop-form');
  
  if (document.body.scrollTop > topOffset || document.documentElement.scrollTop > topOffset) {
    el.style.display = 'block';
    setTimeout(() => {
      el.style.opacity = '1';
    }, 0);
  } else {
    el.style.opacity = '0';
    
    setTimeout(() => {
      el.style.display = 'none';
    }, 400);
  }
}

export function homeScrollListener() {
  return showHideScrollUpButton(50);
}
