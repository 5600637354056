<template>
  <!--section-color services-->
  <div class="section has-background-primary has-text-white" id="services">
    <div class="container">
      <div class="columns">
        <div class="column about-me">
          <h1 class="title has-text-centered section-title has-text-white">Interventions</h1>
        </div>
      </div>
        <div class="columns is-multiline">
          <intervention title="Individual Counseling" :icon="['fal', 'comment-medical']" />
          <intervention title="Grief & Loss" :icon="['fal', 'heart-broken']" />
          <intervention title="Trauma & Abuse" :icon="['fal', 'user-injured']" />
          <intervention title="Anxiety & Depression" :icon="['fal', 'head-side-virus']" />
          <intervention title="Spirituality & Faith" :icon="['fal', 'praying-hands']" />
          <intervention title="Cognitive Processing Therapy" :icon="['fal', 'brain']" />
          <intervention title="Insomnia Interventions" :icon="['fal', 'procedures']" />
          <intervention title="EMDR" :icon="['fal', 'head-side-medical']" />
        </div>
      </div>
    </div>
</template>

<script>
  import Intervention from './intervention';
  
  export default {
    components: {
      Intervention
    }
  }
</script>
