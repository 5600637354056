<template>
  <!--has-background-primary-->
  <div class="section" id="encouragement">
    <div class="container">
      <div class="columns">
        <div class="column">
          <h1 class="title has-text-centered section-title has-text-link">Encouragement</h1>
        </div>
      </div>
      <div class="columns is-multiline" data-aos="fade-in" data-aos-easing="linear">
        <ig-pic @markUpReady="picsLoaded++" 
                class="column is-4" 
                v-for="pic in pics" 
                :key="pic.shortcode" 
                v-bind="pic" />
      </div>
    </div>
  </div>
</template>

<script>
  import IgPic from './ig-pic';
  
  export default {
    components: {
      IgPic
    },
    data() {
      return {
        pics: [],
        picsLoaded: 0
      }
    },
    mounted() {
      fetch('/.netlify/functions/actions?a=ig-latest')
        .then(res => res.json())
        .then(json => {
          this.pics = json.map(picInfo => {
              return {
                shortcode: picInfo.node.shortcode
              }
            });
          fetch('/.netlify/functions/actions?a=ig-update-cache', {
            method: 'POST',
            body: JSON.stringify(this.pics.map(pic => pic.shortcode))
          })
          .then(res => res.json())
          .then(json => console.log(json))
          .catch(() => null);
        });
    },
    watch: {
      picsLoaded: function () {
        let { pics, picsLoaded } = this;
        
        if (pics.length && picsLoaded === pics.length) {
          this.$nextTick()
            .then(() => {
              window.instgrm.Embeds.process();
            });
        }
      }
    }
  }
</script>
