<template>
  <div v-html="embedMarkUp"></div>
</template>

<script>
  export default {
    props: {
      shortcode: String,
    },
    data() {
      return {
        embedMarkUp: ''
      }
    },
    mounted() {
      fetch(`https://api.instagram.com/oembed?url=https://www.instagram.com/p/${this.shortcode}/&omitscript=true`) //&hidecaption=true
        .then(res => res.json())
        .then(res => {
          this.embedMarkUp = res.html
          this.$emit('markUpReady')
        });
    }
  }
</script>
